.menu-links a {
    text-decoration: none;
    list-style: none;
    color: #fff;
}

.box-links a {
    text-decoration: none;
    list-style: none;
    color: #333;
}

.btn-tools {
    position: fixed !important;
    bottom: 2rem;
    right: 2rem;
    background-color: cyan !important;
    padding: 1rem !important;
    color: #000 !important;
}

.logo-dashboard {
    margin-top: 10px;
    width: 100%;
    max-width: 260px;
}

.adjustNameAvatar {
    margin-right: 1rem;
    padding-top: 1rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px
}