:root {
    --red-switch: rgb(255, 0, 68);
    --cian-switch: #00ffff;
    --grey-dark: #22252c;
    --base-height: 500px;
    --left-rounded: 30px 0 0 30px;
    --right-rounded: 0 30px 30px 0;
    --full-rounded: 8px;
}

.grid-images {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    /* gap: 1.2rem; */
    max-width: 80%;
}

div.grid-images > div:nth-child(1) {
    /* background-color: var(--grey-dark); */
    width: 55%;
    min-height: var(--base-height);
    /* border-radius: 30px; */

    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: last baseline;
    gap: 1rem;
    
    padding: 2.2rem 1.2rem;
}

div.grid-images > div:nth-child(2) {
    /* background-color: var(--grey-dark); */
    width: 45%;
    min-height: var(--base-height);
    /* border-radius: 30px; */

    /* padding: 3.5rem 2.32rem; */
    padding: 2.2rem .2rem;
}

/* cards */
div.little-cubes {
    width: 140px;
    height: 160px;
    background-color: var(--cian-switch);
    /* background: url('https://resource0.escort-ireland.com?id=fbf8ac1e8d30155584903adbb9487dd0fe6d432a7c780c5b85d0ab8c05b1116159efc5698552cb65105732cf9571c5b2c58e0ce2b13c524da0d0445d31d99d727fcd5741c9e37eb5e3589ee6d84520c09051bad1053f9399c3851d0c9f776f397a04167189e9534cc2d5c2c210289186b7273bb4a771825ab5ce04a599ea1bbd');
    background-size: cover; */
    border-radius: var(--full-rounded);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

div.main-card {
    width: 100%;
    height: 100%;
    /* background-color: var(--cian-switch); */
    /* 'https://resource7.escort-ireland.com?id=fbf8ac1e8d30155584903adbb9487dd0fe6d432a7c780c5b85d0ab8c05b11161de256317348d53d386571813d3bc0cc2c6986a21071f82abda8938e105684e6f0eae12715e7bb1e9b0546a3c754417f7e13e11480d167af2c0286dd2b7abc882c9f08767cf3174f025e03cd23747ff5009903a136c5fe0af2a4dd7a813079f80' */
    background: var(--cian-switch) url('https://resource7.escort-ireland.com?id=fbf8ac1e8d30155584903adbb9487dd0fe6d432a7c780c5b85d0ab8c05b11161de256317348d53d386571813d3bc0cc2c6986a21071f82abda8938e105684e6f0eae12715e7bb1e9b0546a3c754417f7e13e11480d167af2c0286dd2b7abc882c9f08767cf3174f025e03cd23747ff5009903a136c5fe0af2a4dd7a813079f80');
    border-radius: var(--full-rounded);
    display: flex;
    justify-content: center;
    flex-direction: column;
}