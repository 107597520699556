
    .App-footer {
      background-color: #161616;
      padding: 30px 0;
      color: #b3b3b3;
      text-align: center;
    }
    
    .Footer-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1200px;
      flex-wrap: wrap;
      margin: 0 auto; 
    }

    .footer-content-left{
      min-width:30%;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      gap: 2rem;
    }
    .footer-content-left div:nth-child(2){
      padding-top: 10px;
    }

    .footer-content-right{
      min-width:30%;
    }
    
    .Footer-logo {
      display: flex;
      align-items: center;
      color: #fff;
      font-weight: bold;
      gap: 5px;
    }
    
    .Footer-logo img {
      max-width: 260px;
      margin-left: 15px;
    }
    
    .Footer-logo-text {
      font-size: 1.2rem;
    }
    
    .Footer-linkedin a,
    .Footer-E-mail a {
      display: inline-block;
      margin: 5px; 
    }
    
    .Footer-linkedin img,
    .Footer-E-mail img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
   
    /* Estilo dos ícones do rodapé */
    .Footer-social-icons {
      display: flex;
      justify-content: center;
      
    }
    
    .Footer-social-icons a {
      display: inline-block;
      margin: 0 10px;
      color: #746f6f;
      text-decoration: none;
      font-size: 1.5rem;
      transition: color 0.2s;
      
    }
    
    .Footer-social-icons a:hover {
      color: #3870ac;
    }
    
    
    
    /* For screens up to 600px width */
    @media screen and (max-width: 600px) {
      .Footer-content {
        flex-direction: column;
        align-items: center;
      }
    
      .Footer-logo img {
        margin-left: 0;
        margin-bottom: 10px;
      }
    
      .Footer-social-icons {
        margin-top: 15px;
      }
    
      .Footer-social-icons a {
        font-size: 1.2rem;
      }
    }
    
    
    @media screen and (min-width: 601px) and (max-width: 900px) {
      .Footer-content {
        justify-content: center;
      }
    
      .Footer-logo img {
        margin-left: 0;
        margin-right: 10px;
      }
    
      .Footer-social-icons {
        margin-top: 10px;
      }
    
      .Footer-social-icons a {
        font-size: 1.3rem;
      }
    }
    