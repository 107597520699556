:root {
  --red1: #EB6361;
  --ok: #64DDBB;
  --yes: #8870FF;
  --check: #44BBFF;
  --label1: #D6D6D6;
  --label2: #32ff7e;
  --ok2: #2F3491;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--label1);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--label1);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--label1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

code {
  font-size: 1.5rem;
  color: var(--ok);
}

.left-form {
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  padding: 2rem;
  border-radius: 15px;
  min-width: 20vw;
  margin-top: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.left-form .label {
  font-size: 1.9rem;
  letter-spacing: 2.5px;
  margin-right: 2rem;
  font-weight: 600;
  color: var(--label1);
}

.left-form input[type='text'] {
  padding: 1rem;
  width: auto;
  min-width: 20vw;
  border: none;
  border-radius: 5px;
  height: 1.31rem;
  margin-right: 0.8rem;
  font-size: 1.5rem;
  background-color: #42414d;
  color: var(--label2);
}

.left-form button.search {
  border-left: 2px solid var(--check);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 5px;
  font-size: 1.2rem;
  background-color: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: var(--label1);
  cursor: pointer;
}

.listBoxClass {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: none;
  padding: 0.95rem;
  border-radius: 5px;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: var(--ok);
}

button.progress {
  margin-top: 3rem;
  margin-bottom: 1rem;
  background-color: var(--ok2);
  border: none;
  padding: 1rem 5rem;
  border-radius: 50px;
  color: #fff;
  font-weight: 500;
  font-size: 1.2rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  cursor: pointer;
}

.progress-loading {
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.errorMsg {
  color: var(--red1);
  letter-spacing: 1px;
  font-size: 2rem;
}

.card-btn-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.card-btn-info span {
  padding-right: 1rem;
}

.App .box-right {
  position: relative;
  padding: 0.4rem 1rem;
}

.App .box-right .card-info {
  width: 100%;
  background: #373c46;
  text-align: left;
  border-radius: 4px;
  min-height: 350px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.App h4 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.334;
  letter-spacing: 0.02rem;
  border-bottom: 1px solid #444444;
  padding: 10px 1rem;
  background: #30353e;
}

.App h5 {
  font-size: 0.9rem;
  font-weight: 400;
  padding: 0px 1rem;
}

.App ul {
  margin-top: 20px;
  border-top: 1px solid #444444;
  padding: 20px 20px 12px 20px;
}

.App ul li {
  font-size: 15px;
  padding: 0px 0rem;
  display: flex;
  justify-content: space-between;
}

.App ul .name {
  font-size: 14px;
  width: 115px;
  padding-right: 0.5rem;
}

.App ul .date {
  font-size: 14px;
  color: #b1b1b1;
  padding-right: 0.5rem;
}

.App ul .status {
  width: 55px;
  font-size: 14px;
  font-weight: 600;
}

.App ul .status.approved {
  color: #61b914
}

.App ul .status.pending {
  /* color: #fce74e; */
  color: #d9bf00;
}

.App ul .status.rejected {
  /* color: #ff8282; */
  color: #ff4f4f;
}

.titleCard {
  font-size: 1.12rem !important;
}

.header-banner {
  padding: 5vh;
  width: 100%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.19));
  margin-bottom: 3rem;
}