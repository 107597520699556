
  .app-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #161616;
    box-shadow: 0px 3px 10px #000000;
  }

  .nav-bar{
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem;
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  
  
  .app-logo {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    
  }
  
  .app-logo img {
    max-width: 260px;
    margin-right: 10px; 
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  
  .nav-list {
    display: flex;
    align-items: center;
    margin-left: 3rem;
  }
  
  .nav-list div {
    display: flex;
    justify-content: center;
    list-style: none;
  }

  .nav-item{
    margin: 0 15px;
  }
  
  .App-nav-link  {
    text-decoration: none;
    font-size: 1.15rem;
    color: #fff;
    font-weight: 400;
  }

  .App-nav-link:hover {
    background-color: #007bff;
    transition: 0.5em;
    border-radius: 3px; 
  }
  .login-button{
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .login-button select{
    background-color: transparent;
    border: none;
    color: #fff;
    margin-right: 2rem;
  }

  .login-button option{
    background-color: #007bff;
  }

  .login-button button{
    border: none;
    background-color: #f53b57;
    padding: 10px 15px;
    border-radius: 5px;
  }

  .login-button button:hover{
    background-color: #ef5777;
    transition:0.5s;
    border-radius: 5px; 
  }

  .login-button  button a {
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    font-size: 1.1rem;
  }
 
  .mobile-button{
    display: none;
  }

  .login-button img {
    color: #fff;
    width: 20px;
  }
    
  @media screen and (min-width:731px) {
  .mobile-menu-icon,
  .mobile-menu{
    display: none;
  }
 }
  @media screen and (min-width:731px) {
   .mobile-menu-icon,
  .mobile-menu{
    display: none;
  } 
 
 }
  @media screen and (max-width:730px) {
  
  .nav-list {
    display: none;
  } 

  .login-button button{
    display: none;
  }
  .mobile-menu-icon{
    display: block;
  }
  
  .mobile-menu-icon button {
    background-color: transparent;
    border:none;
    cursor: pointer;
    position: fixed;
    right: -5%; 
    top: 1%;
    width: 8em;
    align-items: center;
      
  } 

  .mobile-menu div {  
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-bottom: 1rem;
  } 
  
  .mobile-menu div a {
    display: block;
    padding-top: 1.2rem;
    text-decoration: none;
    font-size: 1.10rem;
    color: #fff;
    font-weight: 400;
  }

  .mobile-menu div a:hover {
    color: #007bff;
    transition: 0.5s;
    border-radius: 3px;
    
  }
  
   .mobile-button {
    display: block;
    padding: 1rem 5rem;
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    font-size: 1.9rem;
    border-radius: 3px;
  }
  .mobile-button a {
    text-align: center;
    display: block;
    padding-top: 0.7rem;
    text-decoration: none;
    font-size: 1.15rem;
    color: #fff;
    font-weight: 500;
    margin-bottom: 5px;
  }

   .mobile-button button {
    width: 100%;
    border: none;
    background-color: #f53b57;
    padding: 1px;
    border-radius: 30px;
  }
  .mobile-button button:hover{
    background-color: #ef5777;
    transition: 0.5s;
    border-radius: 30px; 
  }
  .login-button select{
    margin-right: 3rem;
    
  }
  .login-button option{
    background-color: #007bff;
  }

 } 
