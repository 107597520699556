body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav.menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
nav.menu ul {
  list-style: none;  
}
nav.menu ul li {
  display: inline;
  padding-right: 2rem;  
}
nav.menu ul li a{ 
  text-decoration: none;
  font-size: 1.4rem;
  color: #22ff22;
}