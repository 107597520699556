@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Ubuntu:wght@500;700&display=swap');
html{
  scroll-behavior: smooth;
}
body {
  line-height: 1.6;
  color: #fff;
  background-color: #000000; 
  margin: 0;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Ubuntu', sans-serif;
}

p,span,label,ul,li{
  font-family: 'Open Sans', sans-serif;
}

.App-header-content {
  background: linear-gradient( rgba(0, 0, 0, 0.6) 100%, rgba(0, 0, 0, 0.6)100%), url('../../assets/img/banner.png');
  height: 100vh; 
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  align-content: center;
  flex-direction: column;
}
  

.App-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #007bff;
  margin-top: 300px;
}

.App-sub-title {
  font-size: 1.5rem;
  color: #ffffff;
  margin-top: 5vh;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction:column;
}

.App-sub-title p{
  width: 45%;
  justify-content: center;
}

.App-header-button {
  font-size: 1.2rem;
  background-color: #f53b57; 
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 13vh;
  transition: 0.2s, color 0.2s;
}
.App-header-button a{
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 2px;
}

.App-header-button:hover {
  background-color: #ef5777; 
  color: #fff; 
}

.Services {
  padding: 50px;
  background-color: #0000;
  display: flex;
  justify-content: center;
  align-content: center; 
  flex-direction:column;
  margin-top: 2rem;
  margin-bottom: 3rem; 
  
} 

.content-service-card{
  margin-top: 2rem;
  display: flex;
  gap:80px;
  justify-content: center;
  align-content: center; 
}

.content-service-card .Service-card,
.content-service-card .Service-card2,
.content-service-card .Service-card3 {
  background-color: #ebe8e8;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 3px 3px 5px rgba(150, 148, 148, 0.486);
  max-width: 30vh;
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
}

.Service-card:hover,
.Service-card2:hover,
.Service-card3:hover {
  background-color: #c9cbce;
  color: #fff;
}

.Service-icon,
.Service-icon2,
.Service-icon3 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: #007bff;
}

.Service-name,
.Service-name2,
.Service-name3 {
  font-size: 1rem;
  font-weight: bold;
  color: #f53b57;
  margin-bottom: 20px;
}

.Service-description,
.Service-description2,
.Service-description3 {
  color: #666;
}


@media (max-width: 768px) {
  .Services {
    flex-direction: column; 
    align-items: center;
  }

  .content-service-card {
    flex-direction: column; 
    gap: 20px;
    align-items: center;
  }
  .App-header-content{
    align-items: center;
    justify-content: center;
  }
  .App-header-button {
    margin-top: 1vh;
  }
  .App-sub-title p{
    margin-top: 0vh;
    width: 80%;
  }
  .App-title{
    margin-top: 15vh;
    text-align:center;
    letter-spacing: -3px;
  }
  .App-header-content {
    background: linear-gradient( rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.8)100%), url('../../assets/img/bg/bg_top_mobile.jpg');
    height: 100vh; 
    width: 100%;
    background-size: cover;
    background-position: center;
    display: flex;
    align-content: center;
    flex-direction: column;
  }
 
}



.about {
  background: linear-gradient( rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 0.8)100%), url('../../assets/img/bg/pexels-abilitv-on-twitch-life-from-my-wheelchair-2354967.jpg');
  color: #f5f5f5;
  padding: 60px 0;
  text-align: center;
  align-items: center;
  background-size: cover;
}


.sub-title-light {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #e0e0e0;
}

.sub-title-dark {
  font-size: 3rem;
  margin-bottom: 20px;
  color:#007bff
}

.about p {
  font-size: 1.1rem;
  color: #b0b0b0;
  line-height: 1.8;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto; 
  width: 30rem;
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .about {
    padding: 40px 0;
  }

  .titulo-title {
    font-size: 2.5rem;
  }

  .about p {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width:350px;
    text-align: justify;
    letter-spacing: -2px;
  }
  
  
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .about {
    padding: 40px 0;
  }

  .titulo-title {
    font-size: 2.5rem;
  }

  .about p {
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 30px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    width:500px;
    text-align: justify;
    letter-spacing: -2px;
  }
  
}